import React from "react";
import { FilledTextField } from "./Textfield";
import { FilledTextFieldStyledProps } from "./Textfield/FilledTextField/FilledTextField.styles";
import FormInputLabel, { InputLabelProps } from "./InputLabel/FormInputLabel";

const FilledTextFieldWithLabel: React.FC<
  FilledTextFieldStyledProps & InputLabelProps
> = ({
  width,
  height,
  textfieldinputfontsize,
  label,
  type,
  required,
  name,
  onBlur,
  onChange,
  error,
  autoComplete,
  helperText,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <FormInputLabel label={label} name={name} />
      <FilledTextField
        width={width}
        name={name}
        height={height}
        required={required}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        textfieldinputfontsize={textfieldinputfontsize}
        error={error}
        helperText={helperText}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default FilledTextFieldWithLabel;
