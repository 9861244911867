import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks";
import { User } from "firebase/auth";
import { useNavigate } from "react-router";

interface ConfirmationResult {
  sendVerification: () => Promise<void>;
  verificationLoading: boolean;
  error: string | null;
  user: User | null;
}

export const useConfirmation = (): ConfirmationResult => {
  const navigate = useNavigate();
  const { authService, user } = useAuth();
  const [verificationLoading, setverificationLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handlePageFocus = async () => {
      user && (await authService.reloadUser(user));
      user?.emailVerified && navigate("/dashboard");
    };

    window.addEventListener("focus", handlePageFocus);

    return () => {
      window.removeEventListener("focus", handlePageFocus);
    };
  }, [authService, navigate, user]);

  const sendVerification = async () => {
    try {
      setverificationLoading(true);
      user && (await authService.sendEmailVerification(user));
    } catch (error) {
      //TODO:
      console.error("ReSendVerification error:", error);
      setError("Failed. Please try again.");
    } finally {
      setverificationLoading(false);
    }
  };

  return {
    sendVerification,
    verificationLoading,
    error,
    user,
  };
};
