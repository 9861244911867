import { useEffect, useRef } from "react";

export type DrawFunction = (context: CanvasRenderingContext2D) => void;

export const useCanvas = (draw: DrawFunction) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!context || !canvas) return;
    // Get the device pixel ratio
    const dpr = window.devicePixelRatio || 1;

    canvas.width = canvas.clientWidth * dpr;
    canvas.height = canvas.clientHeight * dpr;

    draw(context);

    // Cleanup
    return () => {};
  }, [draw]);

  return canvasRef;
};
