import { Button } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface OutlinedButtonStyledProps {
  outlinedColor: string;
}

const OutlinedButtonStyled = styled(Button)<
  SxProps<Theme> & OutlinedButtonStyledProps
>(({ theme, outlinedColor }) => ({
  color: theme.palette.text.quinary,
  border: `1px solid ${outlinedColor}`,
  "&:hover": {
    backgroundColor: outlinedColor,
    color: theme.palette.common.white,
  },
}));

export default OutlinedButtonStyled;
