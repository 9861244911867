// PrivateRoute.tsx
import React from "react";
import { Link, To } from "react-router-dom";
export interface TextLinkProps {
  to: To;
  text?: string;
  color?: string;
}
export const TextLink: React.FC<TextLinkProps> = ({ to, text, color }) => {
  return (
    <Link to={to} style={{ color: color, textDecoration: "none" }}>
      {text}
    </Link>
  );
};
