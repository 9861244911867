import { createFireStoreService } from "../../../services";
import { Timestamp } from "firebase/firestore";

export interface FireStoreUser {
  name: string;
  surname: string;
  email: string;
  roles: string[];
  uid?: string;
  createdAt?: Timestamp;
}

export const userService = createFireStoreService<FireStoreUser>("users");
