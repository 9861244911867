import React from "react";
import { IconButtonStyled } from "./icon-button.styles";
import { IconButtonStyledProps } from "./interface";
import { CircularProgress } from "@mui/material";

const PrimaryIconButton: React.FC<IconButtonStyledProps> = ({
  children,
  onClick,
  disabled,
  type,
  loading,
  ...rest
}) => {
  return (
    <IconButtonStyled
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...rest}
      sx={{ color: "text.primary" }}
    >
      {loading ? <CircularProgress color="secondary" size="20px" /> : children}
    </IconButtonStyled>
  );
};

export default PrimaryIconButton;
//?Reconfig all from this
