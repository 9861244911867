import React, { useState } from "react";
import Chart from "react-google-charts";
import { getRandomNumber } from "../../../utils/helper";
import { DrawFunction } from "../hooks";
import { Canvas } from "./Canvas";
import { PrimaryIconButton } from "../../../components";
import ShuffleIcon from "@mui/icons-material/Shuffle";
export interface Particle {
  x: number;
  y: number;
  r: number;
  color: string;
  x2?: number;
  y2?: number;
}

export const DigitalBrainVisualizer = () => {
  let particles: Particle[] = [];
  var colors: string[] = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
  ];

  const [currentDataSetIndex, setCurrentDataSetIndex] = useState(0);

  const createParticles = (
    num: number,
    color: string,
    context: CanvasRenderingContext2D
  ) => {
    for (let i = 0; i < num; i++) {
      particles.push({
        x: getRandomNumber(context.canvas.width),
        y: getRandomNumber(context.canvas.height),
        r: 8,
        color: color,
      });
    }
    for (let i = particles.length - num; i < particles.length - 1; i++) {
      particles[i + 1].x2 = particles[i].x;
      particles[i + 1].y2 = particles[i].y;
    }
  };

  const drawParticle = (
    context: CanvasRenderingContext2D,
    particle: Particle
  ) => {
    const { x, y, r, color } = particle;
    context.beginPath();
    context.arc(x, y, r, 0, 2 * Math.PI);
    context.fillStyle = color;
    context.fill();
  };

  const drawLines = (
    context: CanvasRenderingContext2D,
    particle: Particle,
    lineWidth: number
  ) => {
    context.lineWidth = lineWidth;
    const { x, y, color, x2, y2 } = particle;
    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x2!, y2!);
    context.strokeStyle = color;
    context.stroke();
  };

  const draw: DrawFunction = (context) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    dataSets[currentDataSetIndex].data.forEach((data, index) => {
      if (index > 0)
        createParticles(data[1] as number, colors[index - 1], context);
    });

    particles.forEach((particle, index) => {
      drawParticle(context, particle);
      drawLines(context, particle, 0.5);
    });
  };

  const handleRandomButtonClick = () => {
    const randomIndex = Math.floor(Math.random() * dataSets.length);
    setCurrentDataSetIndex(randomIndex);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1, height: "50%" }}>
        <Chart
          chartType="PieChart"
          data={dataSets[currentDataSetIndex].data}
          options={dataSets[currentDataSetIndex].options}
          height={"100%"}
        />
      </div>
      <Canvas
        draw={draw}
        style={{ flex: 1, height: "50%", width: "80%", alignSelf: "center" }}
      />

      <PrimaryIconButton
        onClick={handleRandomButtonClick}
        style={{ alignSelf: "flex-end", position: "absolute" }}
      >
        <ShuffleIcon />
      </PrimaryIconButton>
    </div>
  );
};
const dataSets = [
  {
    data: [
      ["Pizza Type", "Probability"],
      ["Margherita", 4],
      ["Pepperoni", 3],
      ["Vegetarian", 2],
      ["Hawaiian", 1],
    ],
    options: {
      title: "Probability Distribution of Pizza Types",
    },
  },
  {
    data: [
      ["Accident Type", "Probability"],
      ["Car Accident", 1],
      ["Bicycle Accident", 5],
      ["Public Transportation Incident", 2],
      ["No Incident", 6],
    ],
    options: {
      title: "Accident Probabilities in Different Scenarios",
    },
  },
  {
    data: [
      ["Activity Type", "Probability"],
      ["Working Out", 3],
      ["Reading", 2],
      ["Cooking", 4],
      ["Playing Games", 1],
    ],
    options: {
      title: "Probability of Engaging in Daily Activities",
    },
  },
  {
    data: [
      ["Entertainment Activity", "Probability"],
      ["Coding", 2],
      ["Listening to Music", 3],
      ["Meeting Friends", 1],
      ["Watching Movies", 4],
    ],
    options: {
      title: "Probability of Various Entertainment Activities",
    },
  },
  {
    data: [
      ["Food Item", "Probability"],
      ["Cheeseburger", 3],
      ["Vegan Burger", 2],
      ["Fish Tacos", 1],
      ["Sushi", 4],
    ],
    options: {
      title: "Probability Distribution of Food Preferences",
    },
  },
  {
    data: [
      ["Workplace Situation", "Probability"],
      ["Email Inbox Zero", 5],
      ["Late for Meeting", 1],
      ["Project Deadline Met", 3],
      ["Coffee Spill", 2],
    ],
    options: {
      title: "Probabilities of Various Workplace Scenarios",
    },
  },
  {
    data: [
      ["Daily Routine Activity", "Probability"],
      ["Morning Run", 4],
      ["Afternoon Nap", 2],
      ["Yoga Session", 3],
      ["Midnight Snack", 1],
    ],
    options: {
      title: "Probability of Engaging in Daily Routines",
    },
  },
  {
    data: [
      ["Weather Condition", "Probability"],
      ["Sunny Day", 4],
      ["Rainy Day", 3],
      ["Snowy Day", 2],
      ["Cloudy Day", 1],
    ],
    options: {
      title: "Probability of Weather Conditions",
    },
  },
  {
    data: [
      ["Travel Mode", "Probability"],
      ["Driving", 1],
      ["Cycling", 5],
      ["Public Transportation", 2],
      ["Walking", 6],
      ["Skating", 3],
      ["Taxi/Ride-Share", 7],
    ],
    options: {
      title: "Probabilities of Different Travel Modes",
    },
  },
  {
    data: [
      ["Exercise Type", "Probability"],
      ["Running", 3],
      ["Yoga", 2],
      ["Weightlifting", 4],
      ["Swimming", 1],
      ["Pilates", 6],
    ],
    options: {
      title: "Probability Distribution of Exercise Types",
    },
  },
  {
    data: [
      ["Social Media Platform", "Probability"],
      ["Instagram", 2],
      ["Twitter", 3],
      ["Facebook", 1],
      ["TikTok", 4],
      ["LinkedIn", 6],
      ["Snapchat", 5],
      ["Pinterest", 7],
    ],
    options: {
      title: "Probability of Using Different Social Media Platforms",
    },
  },
  {
    data: [
      ["Gaming Genre", "Probability"],
      ["FPS", 3],
      ["Strategy", 2],
      ["Strategy", 2],
      ["Simulation", 1],
      ["Simulation", 1],
      ["RPG", 4],
    ],
    options: {
      title: "Probability Distribution of Gaming Genres",
    },
  },
  {
    data: [
      ["Breakfast Item", "Probability"],
      ["Omelette", 5],
      ["Cereal", 1],
      ["Pancakes", 3],
      ["Smoothie", 2],
      ["Waffles", 6],
      ["Fruit Bowl", 7],
    ],
    options: {
      title: "Probability of Breakfast Choices",
    },
  },
  {
    data: [
      ["Learning Resource", "Probability"],
      ["Books", 4],
      ["Online Courses", 2],
      ["Workshops", 3],
      ["Self-Study", 1],
      ["Tutoring", 5],
      ["Tutoring", 5],
    ],
    options: {
      title: "Probability of Using Learning Resources",
    },
  },
];
