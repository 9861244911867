import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks";

export function ConfirmationRouteWrapper() {
  const { user } = useAuth();
  if (user) {
    if (user.emailVerified) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
}
