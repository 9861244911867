import React from "react";
import { HomeBG } from "../assets";
import { AuthBox, BackgroundBox } from "../components";
import { LoginForm } from "../features";

export const LoginPage: React.FC = () => {
  return (
    <BackgroundBox imageurl={HomeBG}>
      <AuthBox>
        <LoginForm />
      </AuthBox>
    </BackgroundBox>
  );
};
