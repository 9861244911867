import React from "react";
import { Typography } from "@mui/material";
import {
  PrimaryContainedButton,
  ApplicationIcon,
  SecondaryContainedButton,
} from "../../../components";
import { useNavigate } from "react-router";

export const AuthTemplate: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <ApplicationIcon width={210} height={180} />
      <Typography
        variant="h2"
        align="center"
        fontWeight="regular"
        color="text.primary"
        lineHeight={2}
      >
        Welcome to Digital Brain Technology
      </Typography>
      <PrimaryContainedButton onClick={() => navigate("/signup")}>
        <Typography variant="body1">Sign Up</Typography>
      </PrimaryContainedButton>
      <SecondaryContainedButton onClick={() => navigate("/login")}>
        <Typography variant="body1">Login</Typography>
      </SecondaryContainedButton>
      <Typography
        variant="body2"
        align="center"
        color="text.primary"
        paddingTop="24px"
      >
        By signing up, I agree to the Terms and Conditions and Privacy Policy
      </Typography>
    </div>
  );
};
