import React from "react";
import { DrawFunction, useCanvas } from "../hooks";
interface CanvasProps extends React.HTMLProps<HTMLCanvasElement> {
  draw: DrawFunction;
}

export const Canvas: React.FC<CanvasProps> = ({ draw, ...rest }) => {
  const canvasRef = useCanvas(draw);

  return <canvas {...rest} ref={canvasRef} />;
};
