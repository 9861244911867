import { useState } from "react";
import { useAuth } from "../../../hooks";
import { FormikHelpers } from "formik";
import { LoginFormValues } from "../../../lib";
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router";

interface LoginResult {
  login: (
    email: string,
    password: string,
    helpers: FormikHelpers<LoginFormValues>
  ) => Promise<void>;
  loading: boolean;
  error: string | null;
}

export const useLogin = (): LoginResult => {
  const { authService, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const login = async (
    email: string,
    password: string,
    helpers: FormikHelpers<LoginFormValues>
  ) => {
    try {
      setLoading(true);
      await authService.logIn(email, password);
      user && navigate("/dashboard");
    } catch (error) {
      //TODO:
      console.error("SignUp error:", error);
      if (
        error instanceof FirebaseError &&
        error.code === "auth/invalid-credential"
      ) {
        helpers.setErrors({
          email: "Incorrect email or password.",
          password: "Incorrect email or password.",
        });
      } else {
        console.error("SignUp error:", error);
        setError("Login failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return { login, loading, error };
};
