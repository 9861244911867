import { createTheme } from "@mui/material";
const { palette } = createTheme();

export const darkTheme = createTheme({
  typography: {
    fontFamily: "'Montserrat-Alt1', sans-serif",
    h1: {
      fontSize: 32,
      fontWeight: 400,
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#f7f2fa",
      paper: "#ffffff",
    },
    text: {
      primary: "#1c1b1f",
      secondary: "#ffffff",
      tertiary: "#018bff",
      quaternary: "#cc01ff",
      quinary: "#18da07",
      disabled: "#1c1b1f80",
    },

    primary: palette.augmentColor({
      color: {
        main: "#018BFF", // Customize the primary color for buttons
        contrastText: "#FFFFFF",
      },
    }),
    secondary: palette.augmentColor({
      color: {
        main: "#FFFFFF", // Customize the primary color for buttons
        contrastText: "#018BFF",
      },
    }),
    // Customize the disabled button color
    action: {
      disabled: "#1c1b1f80", // Set the text color for the first disabled state
      disabledBackground: "#1c1b1f80", // Set the background color for the second disabled state
      secondaryDisabled: "#FFFFFF",
      secondaryDisabledBackground: "#018BFF80",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "0px 1px 2px 0px rgba(28, 27, 31, 0.3)", // Adjust the color and opacity
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 2px 0px rgba(1,139,255, 0.15)",
        },
      },
    },
  },
});
