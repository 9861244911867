import { PageContainer } from "../layouts";
import { DigitalBrainVisualizer } from "../features";

export const DashBoardPage = () => {
  return (
    <PageContainer>
      <DigitalBrainVisualizer />
    </PageContainer>
  );
};
