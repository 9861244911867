import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";
import { TextField } from "@mui/material";

export interface FilledTextFieldStyledProps {
  width?: string;
  height?: string;
  textfieldinputfontsize?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  required?: boolean | undefined;
  name?: string | undefined;
  value?: unknown;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  error?: boolean | undefined;
  helperText?: string | boolean | undefined;
  errorMessage?: string;
  autoComplete?: string;
}

export const FilledTextFieldStyled = styled(TextField)<
  SxProps<Theme> & { theme?: Theme } & FilledTextFieldStyledProps
>(
  ({
    theme,
    width,
    height,
    textfieldinputfontsize: textFieldInputFontSize,
  }) => ({
    width: width ?? "100%",
    id: "filled-basic",
    borderRadius: "4px 4px 0px 0px",
    justifyContent: "center",
    textTransform: "none",
    "& .MuiInputBase-input": {
      padding: "0px 10px",
      height: height ?? "40px",
      borderRadius: "inherit",
      backgroundColor: theme.palette.primary.contrastText,
      fontSize: textFieldInputFontSize ?? "14px",
    },
    "& .MuiFilledInput-root:before": {
      borderBottomColor: `${theme.palette.primary.main}`,
    },

    "& .MuiInputLabel-filled.Mui-required": {
      color: theme.palette.error.main,
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
      position: "absolute",
      top: 40,
      color: theme.palette.error.main,
      fontSize: "8px",
    },
  })
);
