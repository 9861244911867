import { Divider } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";

const DividerStyled = styled(Divider)<SxProps<Theme> & { theme?: Theme }>(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  })
);

export default DividerStyled;
