import React, { ChangeEvent } from "react";

import { FormControlLabel } from "@mui/material";
import CheckboxStyled from "./checkbox.styles";

interface OutlinedCheckboxProps {
  fontSize?: string;
  name?: string | undefined;
  checked: boolean | undefined;
  label?: string;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
}

const LabeledCheckbox: React.FC<OutlinedCheckboxProps> = ({
  onChange,
  checked,
  fontSize,
  name,
  label,
}) => {
  const [isChecked, setChecked] = React.useState(checked);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange && onChange(event, event.target.checked);
  };
  return (
    <FormControlLabel
      control={
        <CheckboxStyled
          fontSize={fontSize}
          onChange={handleChange}
          name={name}
          checked={isChecked}
          variant="outlined"
        />
      }
      label={label}
    />
  );
};

export default LabeledCheckbox;
