import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Import Firebase configuration from external file
import { firebaseConfig } from "../config";

// Initialize the Firebase app with the provided configuration
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and obtain a reference to the authentication service
const auth = getAuth(firebaseApp);

// Initialize Firestore and obtain a reference to the Firestore service
const db = getFirestore(firebaseApp);

export { auth, db };
