import React, { ReactNode } from "react";
import BackgroundBoxStyled from "./BackgroundBox.styles";

interface BackgroundBoxProps {
  imageurl: string;
  children: ReactNode;
}

const BackgroundBox: React.FC<BackgroundBoxProps> = ({
  imageurl,
  children,
}) => {
  return (
    <BackgroundBoxStyled imageurl={imageurl}>{children}</BackgroundBoxStyled>
  );
};

export default BackgroundBox;
