import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

const signUpValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters"),
  surname: Yup.string()
    .required("Surname is required")
    .min(2, "Surname must be at least 2 characters")
    .max(50, "Surname must be at most 50 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      "Invalid email format"
    ),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Password confirmation is required"),
});

export interface SignUpFormValues {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const useSignUpFormik = (
  initialValues: SignUpFormValues,
  onSubmit: (
    values: SignUpFormValues,
    helpers: FormikHelpers<SignUpFormValues>
  ) => void
) => {
  return useFormik({
    initialValues,
    validationSchema: signUpValidationSchema,
    onSubmit,
  });
};
