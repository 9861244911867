import React, { ReactNode } from "react";
import { ApplicationIcon, PrimaryIconButton } from "../components";
import { Paper, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useAuth } from "../hooks";
import { useLogOut } from "../features";
interface PageContainerProps {
  children: ReactNode;
}
const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const theme = useTheme();
  const { firestoreUser } = useAuth();
  const { logout } = useLogOut();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
        width: "1440px",
        maxWidth: "1440px",
        height: "100vh",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <ApplicationIcon width="63px" height="50px"></ApplicationIcon>
          <Typography variant="h3">DIGITAL BRAIN TECHNOLOGY</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography variant="h5">
            {firestoreUser?.name} {firestoreUser?.surname}
          </Typography>

          <PrimaryIconButton>
            <ArrowDropDownCircleOutlinedIcon sx={{ fontSize: "22px" }} />
          </PrimaryIconButton>
          <PrimaryIconButton onClick={logout}>
            <LogoutIcon sx={{ fontSize: "inherit" }} />
          </PrimaryIconButton>
        </Box>
      </Box>
      <Paper
        sx={{
          width: "1280px",
          height: "865px",
          borderRadius: "16px",
          padding: "24px",
          boxShadow: theme.shadows[3],
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default PageContainer;
