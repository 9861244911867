import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

const logInValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 8 characters")
    .required("Password is required"),
});

export interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const useLoginFormik = (
  initialValues: LoginFormValues,
  onSubmit: (
    values: LoginFormValues,
    helpers: FormikHelpers<LoginFormValues>
  ) => void
) => {
  return useFormik({
    initialValues,
    validationSchema: logInValidationSchema,
    onSubmit,
  });
};
