import { useState } from "react";
import { useAuth } from "../../../hooks";
import { useNavigate } from "react-router";

interface LogOutResult {
  logout: () => Promise<void>;
  logoutLoading: boolean;
  error: string | null;
}

export const useLogOut = (): LogOutResult => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const logout = async () => {
    try {
      setLogoutLoading(true);
      await auth.authService.logOut();
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      setError("Logout failed. Please try again.");
    } finally {
      setLogoutLoading(false);
    }
  };
  return {
    logout,
    logoutLoading,
    error,
  };
};
