import React from "react";
import ContainedButtonStyled from "./contained-button.styles";
import { ContainedButtonProps } from "./interface";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
const SecondaryContainedButton: React.FC<ContainedButtonProps> = ({
  children,
  width,
  height,
  onClick,
  loading,
  disabled,
  type,
}) => {
  const theme = useTheme();
  return (
    <ContainedButtonStyled
      onClick={onClick}
      type={type}
      variant="contained"
      color="secondary"
      disabled={disabled}
      sx={{
        width: width,
        height: height,
        "&:hover": { backgroundColor: "secondary.main" },
        "&.Mui-disabled": {
          background: theme.palette.action.secondaryDisabledBackground,
        },
      }}
    >
      {loading ? <CircularProgress color="primary" size="24px" /> : children}
    </ContainedButtonStyled>
  );
};

export default SecondaryContainedButton;
