import React, { createContext, useEffect, useState, ReactNode } from "react";
import { User } from "firebase/auth";

import { FirestoreService, auth } from "../services";
import {
  AuthService,
  FireStoreUser,
  authService,
  userService,
} from "../features";

interface AuthContextProps {
  children: ReactNode;
}

export interface AuthContextValue {
  user: User | null;
  authService: AuthService;
  userService: FirestoreService<FireStoreUser>;
  firestoreUser: FireStoreUser | null;
}

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined
);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [firestoreUser, setFirestoreUser] = useState<FireStoreUser | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      setUser(user);
      setLoading(false);
      if (!user) {
        setFirestoreUser(null);
      } else if (!firestoreUser) {
        try {
          const fetchedUserDocument = await userService.fetchOne(user.uid);
          setFirestoreUser(fetchedUserDocument);
        } catch (error) {
          throw error;
        }
      }
    });
    return () => unsubscribe();
  }, [firestoreUser]);

  const value: AuthContextValue = {
    user,
    authService,
    userService,
    firestoreUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
