import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";
import { Box } from "@mui/material";

const AuthBoxStyled = styled(Box)<SxProps<Theme> & { theme?: Theme }>(
  ({ theme }) => ({
    background: theme.palette.background.default,
    width: "400px",
    height: "580px",
    borderRadius: "12px",
    alignItems: "center",
    padding: "36px",
    display: "flex",
    flexDirection: "column",
  })
);

export default AuthBoxStyled;
