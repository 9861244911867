import { IconButton } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { IconButtonStyledProps } from "./interface";

export const IconButtonStyled = styled(IconButton)<
  SxProps<Theme> & { theme?: Theme } & IconButtonStyledProps
>(({ theme }) => ({
  fontSize: "22px",
}));
