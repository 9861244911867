import { useState } from "react";
import { useAuth } from "../../../hooks";
import { FireStoreUser } from "../services";
import { User } from "firebase/auth";
import { FormikHelpers } from "formik";
import { SignUpFormValues } from "../../../lib";
import { FirebaseError } from "firebase/app";

interface SignUpResult {
  signUp: (
    userData: FireStoreUser,
    password: string,
    helpers: FormikHelpers<SignUpFormValues>
  ) => Promise<void>;
  loading: boolean;
  error: string | null;
}

export const useSignUp = (): SignUpResult => {
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  var userCredential: User | null;
  const signUp = async (
    userData: FireStoreUser,
    password: string,
    helpers: FormikHelpers<SignUpFormValues>
  ) => {
    try {
      setLoading(true);
      userCredential = await auth.authService.signUp(userData.email, password);

      await auth.userService.add({
        ...userData,
        uid: userCredential?.uid ?? "",
      });

      userCredential &&
        (await auth.authService.sendEmailVerification(userCredential));

      helpers.resetForm();
    } catch (error) {
      //TODO:
      if (
        error instanceof FirebaseError &&
        error.code === "auth/email-already-in-use"
      ) {
        helpers.setErrors({
          email: "This email is already in use.",
        });
      } else {
        console.error("SignUp error:", error);
        setError("SignUp failed. Please try again.");
      }

      // Undo or correct the signup process on error
      if (userCredential) {
        await auth.authService.deleteUser(userCredential);
      }
    } finally {
      setLoading(false);
    }
  };

  return { signUp, loading, error };
};
