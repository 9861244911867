import React from "react";
import { HomeBG } from "../assets";
import { AuthBox, BackgroundBox } from "../components";
import { AuthTemplate } from "../features";

export function HomePage() {
  return (
    <BackgroundBox imageurl={HomeBG}>
      <AuthBox>
        <AuthTemplate />
      </AuthBox>
    </BackgroundBox>
  );
}
