import { styled, SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { Box } from "@mui/material";

interface BackgroundBoxStyledProps {
  imageurl: string;
}

const BackgroundBoxStyled = styled(Box)<
  SxProps<Theme> & BackgroundBoxStyledProps
>(({ theme, imageurl }) => ({
  backgroundImage: `url(${imageurl})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100vw",
  height: "100vh",
  minHeight: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: -1,
}));
export default BackgroundBoxStyled;
