import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";
import { Button } from "@mui/material";
interface ContainedButtonStyledProps {
  disabled?: boolean;
}

const ContainedButtonStyled = styled(Button)<
  SxProps<Theme> & { theme?: Theme } & ContainedButtonStyledProps
>(({ theme, disabled }) => ({
  width: "100%",
  height: "40px",
  borderRadius: "100px",
  boxShadow: theme.shadows[1],
  textTransform: "none",
  fontSize: "14px",
  "&.Mui-disabled": {
    cursor: "not-allowed",
  },
}));

export default ContainedButtonStyled;
