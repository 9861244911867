import React from "react";
import {
  FilledTextFieldStyled,
  FilledTextFieldStyledProps,
} from "./FilledTextField.styles";

const FilledTextField: React.FC<FilledTextFieldStyledProps> = ({
  width,
  height,
  textfieldinputfontsize,
  type,
  required,
  name,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  autoComplete,
}) => {
  return (
    <FilledTextFieldStyled
      name={name}
      width={width}
      height={height}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      variant="filled"
      error={error}
      helperText={helperText}
      type={type ?? "text"}
      required={required ?? true}
      textfieldinputfontsize={textfieldinputfontsize}
      id={name}
      autoComplete={autoComplete}
    />
  );
};

export default FilledTextField;
