import React from "react";
import { Typography, useTheme } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  PrimaryContainedButton,
  SecondaryContainedButton,
} from "../../../components";
import { useConfirmation, useLogOut } from "../hooks";

export const ConfirmationTemplate: React.FC = () => {
  const theme = useTheme();
  const { sendVerification, verificationLoading, user } = useConfirmation();
  const { logout, logoutLoading } = useLogOut();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ForwardToInboxIcon
        color="primary"
        sx={{ width: "150px", height: "150px" }}
      />
      <Typography
        variant="h3"
        borderBottom={`1px solid ${theme.palette.text.tertiary}`}
        paddingBottom="8px"
        textAlign="center"
        width="inherit"
      >
        Check Your mail! {user?.email}
      </Typography>

      <Typography variant="body1">
        Please click on the link we’ve sent you by mail to confirm your account.
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "24px",
        }}
      >
        <SecondaryContainedButton
          loading={logoutLoading}
          disabled={logoutLoading}
          onClick={async () => await logout()}
        >
          Back
        </SecondaryContainedButton>
        <PrimaryContainedButton
          loading={verificationLoading}
          disabled={verificationLoading}
          onClick={async () => await sendVerification()}
        >
          Send again
        </PrimaryContainedButton>
      </div>
    </div>
  );
};
