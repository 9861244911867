import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  LoginPage,
  DashBoardPage,
  HomePage,
  SignUpPage,
  ConfirmationPage,
} from "./pages";
import { AuthProvider, AppThemeProvider } from "./contexts";
import {
  PrivateRouteWrapper,
  AnonymousRouteWrapper,
  ConfirmationRouteWrapper,
} from "./lib";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppThemeProvider>
          <Routes>
            <Route element={<AnonymousRouteWrapper />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
            </Route>
            <Route element={<PrivateRouteWrapper />}>
              <Route path="/dashboard" element={<DashBoardPage />} />
            </Route>
            <Route element={<ConfirmationRouteWrapper />}>
              <Route path="/confirmation" element={<ConfirmationPage />} />
            </Route>
          </Routes>
        </AppThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
