import { List, ListItem } from "@mui/material";
import { styled, SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const DiscListStyled = styled(List)<SxProps<Theme> & { theme?: Theme }>(
  ({ theme }) => ({
    listStyleType: "disc",
  })
);

export const DiscListItemStyled = styled(ListItem)<
  SxProps<Theme> & { theme?: Theme }
>(({ theme }) => ({
  display: "list-item",
  padding: "0px",
  marginLeft: "32px",
}));
