import React from "react";
import { ApplicationIconSvg } from "../../assets";
interface SVGProps {
  width?: number | string;
  height?: number | string;
}

const ApplicationIcon: React.FC<SVGProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 323 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ApplicationIconSvg />
    </svg>
  );
};

export default ApplicationIcon;
