import { Theme } from "@mui/material";
import { darkTheme } from "./DarkTheme";
import { lightTheme } from "./LightTheme";

declare module "@mui/material/styles" {
  interface TypeText {
    tertiary?: string;
    quaternary?: string;
    quinary?: string;
  }
  interface TypeAction {
    secondaryDisabled: string;
    secondaryDisabledBackground: string;
  }
}

export interface AppTheme {
  dark: Theme;
  light: Theme;
}

export const appTheme: AppTheme = {
  dark: darkTheme,
  light: lightTheme,
};
