import {
  DocumentData,
  CollectionReference,
  QuerySnapshot,
  addDoc,
  getDocs,
  doc,
  collection,
  updateDoc,
  deleteDoc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  setDoc,
  WithFieldValue,
  serverTimestamp,
} from "firebase/firestore";
import { db } from ".";

export interface FirestoreService<T> {
  collectionPath: string;
  fetchAll: () => Promise<T[]>;
  fetchOne: (id: string) => Promise<T | null>;
  add: (
    data: T & { uid: string; docRef?: DocumentReference<DocumentData> }
  ) => Promise<DocumentReference<DocumentData>>;
  update: (id: string, data: Partial<T>) => Promise<void>;
  remove: (id: string) => Promise<void>;
}

export const createFireStoreService = <T>(
  collectionPath: string
): FirestoreService<T> => {
  const collectionRef: CollectionReference<DocumentData> = collection(
    db,
    collectionPath
  );

  const fetchAll = async (): Promise<T[]> => {
    try {
      const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(
        collectionRef
      );
      return querySnapshot.docs
        .filter((doc) => doc.exists())
        .map((doc) => ({ id: doc.id, ...doc.data() } as T));
    } catch (error) {
      throw error;
    }
  };
  const fetchOne = async (id: string): Promise<T | null> => {
    try {
      const docRef = doc(collectionRef, id);
      const docSnapshot: DocumentSnapshot<DocumentData> = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return { id: docSnapshot.id, ...docSnapshot.data() } as T;
      }
      //?
      return null;
    } catch (error) {
      throw error;
    }
  };

  const add = async (
    data: T & { uid?: string }
  ): Promise<DocumentReference<DocumentData>> => {
    try {
      let docRef: DocumentReference<DocumentData>;

      if (data.uid) {
        docRef = doc(collectionRef, data.uid);
      } else {
        docRef = await addDoc(collectionRef, {});
      }
      await setDoc(docRef, {
        ...(data as WithFieldValue<DocumentData>),
        uid: data.uid || docRef.id,
        createdAt: serverTimestamp(),
      });
      return docRef; // Oluşturulan veya güncellenen doküman referansını döndür
    } catch (error) {
      throw error;
    }
  };

  const update = async (id: string, data: Partial<T>): Promise<void> => {
    try {
      const docRef = doc(collectionRef, id);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error(`Error updating document with ID ${id}:`, error);
      throw error;
    }
  };

  const remove = async (id: string): Promise<void> => {
    try {
      const docRef = doc(collectionRef, id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error(`Error deleting document with ID ${id}:`, error);
      throw error;
    }
  };
  return {
    collectionPath,
    fetchAll,
    fetchOne,
    add,
    update,
    remove,
  };
};
