import React from "react";
import { Typography, useTheme } from "@mui/material";
import {
  PrimaryContainedButton,
  FilledTextFieldWithLabel,
} from "../../../components";
import { SignUpFormValues, TextLink, useSignUpFormik } from "../../../lib";
import { FormikHelpers } from "formik";
import { useSignUp } from "../hooks";

export const SignUpForm: React.FC = () => {
  const initialValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const { signUp, loading } = useSignUp();
  const theme = useTheme();
  const formik = useSignUpFormik(
    initialValues,
    async (
      values: SignUpFormValues,
      helpers: FormikHelpers<SignUpFormValues>
    ) => {
      await signUp(
        {
          name: values.name,
          surname: values.surname,
          email: values.email,
          roles: ["user"],
        },
        values.password,
        helpers
      );
    }
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
      noValidate={true}
    >
      <Typography variant="h2" color="text.primary" fontWeight="regular">
        Sign Up
      </Typography>
      <div
        style={{
          width: "100%",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            gap: "16px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FilledTextFieldWithLabel
            label="Name"
            type="text"
            name="name"
            autoComplete="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <FilledTextFieldWithLabel
            label="Surname"
            type="text"
            name="surname"
            autoComplete="family-name"
            value={formik.values.surname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
            errorMessage={formik.errors.surname}
          />
        </div>
        <FilledTextFieldWithLabel
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          errorMessage={formik.errors.email}
        />
        <FilledTextFieldWithLabel
          label="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          errorMessage={formik.errors.password}
        />
        <FilledTextFieldWithLabel
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          autoComplete="current-password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          errorMessage={formik.errors.confirmPassword}
        />
      </div>
      <PrimaryContainedButton
        type="submit"
        loading={loading}
        disabled={loading}
      >
        <Typography variant="body1">Sign Up</Typography>
      </PrimaryContainedButton>

      <Typography variant="body2" align="center" color="text.primary">
        I have an account.{" "}
        <TextLink
          to={"/login"}
          text="Login"
          color={theme.palette.text.tertiary}
        />
      </Typography>
    </form>
  );
};
