import React from "react";
import ContainedButtonStyled from "./contained-button.styles";
import { ContainedButtonProps } from "./interface";
import CircularProgress from "@mui/material/CircularProgress";
const PrimaryContainedButton: React.FC<ContainedButtonProps> = ({
  children,
  width,
  height,
  loading,
  onClick,
  disabled,
  type,
}) => {
  return (
    <ContainedButtonStyled
      onClick={onClick}
      type={type}
      variant="contained"
      color="primary"
      disabled={disabled}
      sx={{
        width: width,
        height: height,
        "&:hover": { backgroundColor: "primary.main" },
      }}
    >
      {loading ? <CircularProgress color="secondary" size="24px" /> : children}
    </ContainedButtonStyled>
  );
};

export default PrimaryContainedButton;
