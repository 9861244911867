const fileName: string = "Helper";

const truncate = (str: string, num: number): string => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

const genStr = (): string => {
  return (Math.random() + 1).toString(36).substring(2);
};

const getRandomNumber = (max: number, min: number = 0): number => {
  return Math.random() * (max - min) + min;
};
const generateRandomHexColor = (): string => {
  // Generate a random color between black (hex: #000000) and white (hex: #FFFFFF)
  const randomColor: number = Math.floor(Math.random() * 16777215);

  // Convert the color code to hexadecimal format
  const hexColor: string = randomColor.toString(16);

  // Ensure the length of the color code and fill with zeros if necessary
  return "#" + "0".repeat(6 - hexColor.length) + hexColor;
};

export { fileName, truncate, genStr, getRandomNumber, generateRandomHexColor };
