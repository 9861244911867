import { Checkbox } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";

interface CheckboxStyledStyledProps {
  fontSize?: string;
}

const CheckboxStyled = styled(Checkbox)<
  SxProps<Theme> & { theme?: Theme } & CheckboxStyledStyledProps
>(({ theme, fontSize }) => ({
  color: theme.palette.primary.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiSvgIcon-root": { fontSize: fontSize },
}));

export default CheckboxStyled;
