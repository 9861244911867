const firebaseConfig = {
  apiKey: "AIzaSyAqXvN3IQwB6h4J0zFes66eCYMWcFQcSYU",
  authDomain: "digital-brain-io.firebaseapp.com",
  projectId: "digital-brain-io",
  storageBucket: "digital-brain-io.appspot.com",
  messagingSenderId: "1001107667990",
  appId: "1:1001107667990:web:bbeeb6c9449eea9ebf8937",
  measurementId: "G-7Q844390D4",
};

export default firebaseConfig;
