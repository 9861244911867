import React from "react";
import { InputLabelStyled } from "./input-label.styled";

export interface InputLabelProps {
  label?: string;
  name?: string;
}

const FormInputLabel: React.FC<InputLabelProps> = ({ label, name }) => {
  return <InputLabelStyled htmlFor={name}>{label}</InputLabelStyled>;
};

export default FormInputLabel;
