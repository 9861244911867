import React from "react";
import { HomeBG } from "../assets";
import { AuthBox, BackgroundBox } from "../components";
import { SignUpForm } from "../features";

export const SignUpPage: React.FC = () => {
  return (
    <BackgroundBox imageurl={HomeBG}>
      <AuthBox>
        <SignUpForm />
      </AuthBox>
    </BackgroundBox>
  );
};
