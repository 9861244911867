import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks";

export function PrivateRouteWrapper() {
  const { user } = useAuth();

  if (user) {
    if (user.emailVerified) {
      return <Outlet />;
    } else {
      return <Navigate to="/confirmation" replace />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
}
