import React, { ReactNode } from "react";
import AuthBoxStyled from "./AuthBox.styles";

interface AuthBoxProps {
  children: ReactNode;
}

const AuthBox: React.FC<AuthBoxProps> = ({ children }) => {
  return <AuthBoxStyled>{children}</AuthBoxStyled>;
};

export default AuthBox;
