import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import { appTheme } from "../theme";

interface ThemeContextType {
  toggleColorMode: () => void;
  theme: ThemeOptions;
  mode: "light" | "dark";
}

export const ThemeContext = React.createContext<ThemeContextType | undefined>(
  undefined
);
type AppThemeProviderProps = {
  children?: React.ReactNode;
};

export function AppThemeProvider(props: AppThemeProviderProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");

  const [mode, setMode] = React.useState<"light" | "dark">(
    prefersDarkMode ? "dark" : "light"
  );

  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = React.useMemo(
    () => createTheme(appTheme[mode] as ThemeOptions),
    [mode]
  );
  const contextValue: ThemeContextType = {
    toggleColorMode,
    theme,
    mode,
  };

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={mode}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <ThemeContext.Provider value={contextValue}>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />

            {props.children}
          </ThemeProvider>
        </ThemeContext.Provider>
      </motion.div>
    </AnimatePresence>
  );
}

/*export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};*/
