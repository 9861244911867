import { auth } from "../../../services";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  deleteUser,
} from "firebase/auth";

export interface AuthService {
  logIn: (email: string, password: string) => Promise<User | null>;
  signUp: (email: string, password: string) => Promise<User | null>;
  logOut: () => Promise<void>;
  deleteUser: (user: User) => Promise<void>;
  sendEmailVerification: (user: User) => Promise<void>;
  reloadUser: (user: User) => Promise<void>;
}

export const authService: AuthService = {
  logIn: async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  },

  signUp: async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  },

  logOut: async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  },

  deleteUser: async (user: User) => {
    try {
      await deleteUser(user);
    } catch (error) {
      throw error;
    }
  },

  sendEmailVerification: async (user: User) => {
    try {
      await sendEmailVerification(user);
    } catch (error) {
      throw error;
    }
  },

  reloadUser: async (user: User) => {
    try {
      await user.reload();
    } catch (error) {
      throw error;
    }
  },
};
