import { Theme } from "@mui/material/styles";
import { SxProps, styled } from "@mui/system";
import { InputLabel } from "@mui/material";

export const InputLabelStyled = styled(InputLabel)<
  SxProps<Theme> & { theme?: Theme }
>(({ theme }) => ({
  color: theme.palette.text.primary,
  pointerEvents: "none",
  padding: "0px 0px 5px 0px",
  fontSize: "14px",
}));
