import React from "react";
import { Typography, useTheme } from "@mui/material";
import {
  PrimaryContainedButton,
  FilledTextFieldWithLabel,
  LabeledCheckbox,
} from "../../../components";
import { LoginFormValues, TextLink, useLoginFormik } from "../../../lib";
import { FormikHelpers } from "formik";
import { useLogin } from "../hooks";

export const LoginForm: React.FC = () => {
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };
  const theme = useTheme();
  const { login, loading } = useLogin();

  const formik = useLoginFormik(
    initialValues,
    async (
      values: LoginFormValues,
      helpers: FormikHelpers<LoginFormValues>
    ) => {
      await login(values.email, values.password, helpers);
    }
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
      noValidate={true}
    >
      <Typography variant="h2" color="text.primary" fontWeight="regular">
        Log In
      </Typography>
      <div
        style={{
          width: "100%",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FilledTextFieldWithLabel
          label="Email"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          errorMessage={formik.errors.email}
          autoComplete="email"
        />
        <FilledTextFieldWithLabel
          label="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          errorMessage={formik.errors.password}
        />
        <LabeledCheckbox
          name="rememberMe"
          checked={formik.values.rememberMe}
          onChange={formik.handleChange}
          label="Remember me"
        />
      </div>

      <PrimaryContainedButton
        type="submit"
        loading={loading}
        disabled={loading}
      >
        <Typography variant="body1">Login</Typography>
      </PrimaryContainedButton>

      <Typography variant="body2" align="center" color="text.primary">
        I don’t have an account.{" "}
        <TextLink
          to={"/signup"}
          text=" Sign Up "
          color={theme.palette.text.tertiary}
        />
      </Typography>
    </form>
  );
};
